import React from "react";
import { Widget, PopupButton, Sidetab } from "@typeform/embed-react";
import { Container, Button } from "@atoms";

const Embed = ({ id, formtype: type, buttonText, height }) => {
  switch (type) {
    case "widget":
      return (
        <Widget
          id={id}
          style={{ minHeight: `${height}px` }}
          className="flex w-full flex-grow"
        />
      );
    case "popup":
      return (
        <Button color="purple">
          <PopupButton id={id} style={{ fontSize: 20 }}>
            {buttonText}
          </PopupButton>
        </Button>
      );
    case "sidetab":
      return <Sidetab id={id} buttonText={buttonText} />;
    default:
      return <div />;
  }
};

const TypeformEmbed = props => {
  return (
    <Container>
      <Embed {...props} />
    </Container>
  );
};

export default TypeformEmbed;
